<template>
    <ORowContainer>
        <template v-if="toolipCache != null && toolipCache.tooltipDataObject != null">
            <div class="row mb-3 mx-1">
                <div class="col-12 p-2 border-bottom">
                    <OActionSave class="btn btn-sm btn-primary me-1" :row="tooltip" />
                    <OActionCancel class="btn btn-sm btn-secondary" :row="tooltip" />
                </div>
                <div class="col-4">
                    <label>{{ $t("Title") }}</label>
                    <input class="form-control form-control-sm"  v-model="tooltip.Title"/>
                </div>
                <div class="col-4">
                    <label>{{ $t("Tooltip Redirect") }}</label>
                    <ODataLookup :data-object="dsTooltipLkp" :bind="sel => { tooltip.TooltipRef = sel.PrimKey; tooltip.TooltipRefTitle = sel.Title  }" :value="tooltip.TooltipRefTitle"
                        class="form-control form-control-sm" >
                        <OColumn field="ID" />
                        <OColumn field="Title" />
                    </ODataLookup>
                </div>
                <div class="col-4">
                    <label>{{ $t("ID") }}</label>
                    <input class="form-control form-control-sm" v-model="tooltip.ID" />
                </div>
            </div>
            <FroalaEditor v-model="tooltip.Tooltip" @save="toolipCache.tooltipDataObject.save()" :dataObject="toolipCache.tooltipDataObject" :filesDataObject="dsFiles" simple/>
        </template>
    </ORowContainer>
</template> 

<script setup>
import { ref, onMounted, defineProps } from "vue";
import FroalaEditor from "o365.vue.components.FroalaEditor.vue";
import { getOrCreateDataObject } from 'o365-dataobject';
import { OActionSave, OActionCancel } from 'o365-data-components';
import { ODataLookup } from 'o365-datalookup';
import { OColumn } from 'o365-datagrid';
import { getO365TooltipCache } from 'o365-modules';
import { ORowContainer } from 'o365-ui-components';

const props = defineProps({
    tooltipId: String
})

let toolipCache = ref(null);

const tooltip = ref({});

const dsFiles = getOrCreateDataObject({
    "id": "dsFiles",
    "viewName": "sviw_Content_TooltipsAttachments",
    "distinctRows": false,
    "uniqueTable": "stbv_Content_TooltipsAttachments",
    "allowUpdate": true,
    "allowInsert": true,
    "allowDelete": true,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Updated",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileUpdated",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Extension",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileRef",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "CheckedOut",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileName",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileSize",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "PrimKey",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
    ],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});

const dsTooltipLkp = getOrCreateDataObject({
    "id": "dsTooltipLkp",
    "viewName": "sviw_Content_Tooltips",
    "distinctRows": false,
    "uniqueTable": "stbv_Content_Tooltips",
    "allowUpdate": true,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "PrimKey",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Title",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 50,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});

onMounted(async () => {
    toolipCache.value = await getO365TooltipCache();
    tooltip.value = await toolipCache.value.tooltipDataObject.recordSource.refreshRowById(props.tooltipId, {
        returnExisting: false,
        appendRowCount: true 
    });
    dsFiles.recordSource.whereClause = `TooltipRef = '${tooltip.value.PrimKey}'`;
    dsFiles.load();
})
</script>